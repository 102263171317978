/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('@fortawesome/fontawesome-free/js/all.js');

$(document).ready(function()
{

    $('.setMailAdress').html(function() {
            var firstSet = "info";
            var secondSet = Math.pow(2, 6);
            var thirdSet = String.fromCharCode(secondSet);
            var forthSet = "kaya-kebap.de";
            var fifthSet = firstSet + String.fromCharCode(secondSet) + forthSet;

            var result = "< a href='mai' + 'lto' + ':' + fifthSet + '>' + firstSet + thirdSet + forthSet + '</a>' Nachricht ";
            return fifthSet;
        }
    );

    $('.page').hide();

    $('#contact').toggle(2500);

    $('.page_nav').on('click', function(e) {

        e.preventDefault();
        z = $(this).attr('href');
        $('.page').hide();
        $(z).toggle(2500);

    });

    $('[data-toggle="tooltip"]').tooltip({
        animation: true,
        delay: {show: 500, hide: 100}
    });
    if ($("#map-canvas").length !== 0) {

        var markers = [

            {
                "town": 'ueckermuende',
                "title": 'Kaya Kebap',
                "lat": '53.736081',
                "lng": '14.046750',
                "description": "Kaya Kebap <br> Ueckerstr. 97 <br> 17373 Ueckermünde",
                "type": 'Shop'
            }
        ];


        var mapOptions = {
            center: new google.maps.LatLng(markers[0].lat, markers[0].lng),
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.LARGE
            },
            mapTypeId: google.maps.MapTypeId.SATELLITE
        };
        var infoWindow = new google.maps.InfoWindow();
        var latlngbounds = new google.maps.LatLngBounds();
        var map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);
        var i = 0;
        var interval = setInterval(function () {
            if(typeof  $('#map-canvas').data('title') !=  "undefined")
            {
                for(var j = i ; j <= markers.length; j++)
                {
                    if(markers[j].town.match($('#map-canvas').data('title')))
                    {
                        var data = markers[j];
                        i = markers.length - 1;
                        break;
                    }
                }


            } else {

                var data = markers[i];
            }
            var myLatlng = new google.maps.LatLng(data.lat, data.lng);

            var icon = "";
            switch (data.type) {
                case "Shop":
                    icon = "orange";
                    break;
                default:
                    icon = "red";
                    break;
            }
            icon = "http://maps.google.com/mapfiles/ms/icons/" + icon + ".png";
            var marker = new google.maps.Marker({
                position: myLatlng,
                map: map,
                title: data.title,
                animation: google.maps.Animation.DROP,
                icon: new google.maps.MarkerImage(icon)
            });

            (function (marker, data) {
                google.maps.event.addListener(marker, "mouseover", function (e) {
                    infoWindow.setContent(data.description);
                    infoWindow.open(map, marker);
                });
                google.maps.event.addListener(marker, "mouseout", function (e) {
                    infoWindow.close(map, marker);
                });
            })(marker, data);

            latlngbounds.extend(marker.position);
            i++;
            if (i === markers.length) {
                clearInterval(interval);
                var bounds = new google.maps.LatLngBounds();

                map.setZoom(17);
                map.panTo(marker.position);
            }
        }, 80);
    }


});